import React, { useState, useRef, useEffect, useContext } from "react"
import mapboxgl from "mapbox-gl"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"

import "./map.scss"
import * as S from "./styles"
import { useNavigate } from "react-router-dom"
import { Button, TextWrapper } from "../Step1/styles"
import ThemeContext from "../../ThemeContext"
import calculateZodiacSign from "../../utils/calculateZodiacSign"

mapboxgl.accessToken =
  "pk.eyJ1IjoiaW5waG9lbml4IiwiYSI6ImNqc3JmaDEzNDE0NHEzeW9leXdiYTd4OWUifQ.0KPCpScd-8u--GCnwOpG7g"

function Step2(props) {
  const [location, setLocation] = useState("")
  const context = useContext(ThemeContext)
  const { startDate, setStartDate } = context
  const navigate = useNavigate()


  const mapContainer = useRef(null)
  const geocoderContainer = useRef(null)

  const handleClick = () => {
    navigate("/step3")
    //setClick(true)
  }

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11", // Use the Mapbox default style
      center: [-96, 37.8],
      zoom: 3,
    })

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      container: geocoderContainer.current,
    })

    map.addControl(geocoder)

    geocoder.on("result", (event) => {
      setLocation(event.result.place_name)
      console.log(event.result.center)
    })

    return () => {
      map.remove()
      geocoder.clear()
    }
  }, [])

  return (
    <div>
      <TextWrapper>
        {`Seu signo do zodíaco é ${calculateZodiacSign(startDate)}`}
      </TextWrapper>
      <TextWrapper>
        Especifique seu local de nascimento: Usamos estas informações para
        definir a posição e a influência dos planetas em seu mapa astral
      </TextWrapper>
      <div className="mapa">
        <div ref={mapContainer} />
      </div>
      <div ref={geocoderContainer} style={{ padding: "10px" }} />
      <Button onClick={handleClick} type="button">
        Avançar
      </Button>
    </div>
  )
}

export default Step2
