import React from "react"
import * as S from "./styles"
import {useNavigate} from "react-router-dom";

// const cyberPunkGuyOnBuilding = process.env.PUBLIC_URL + "/img/cyberpunk.gif"
const astro1 = process.env.PUBLIC_URL + "/img/astro1.gif"
function Header(props) {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(-1)
    }
  return (
    <S.Image onClick={handleClick}>
      <img src={astro1} alt="astrologia figura animada" />
    </S.Image>
  )
}

export default Header
