import React, { createContext, useContext, useState } from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import ErrorPage from "./components/ErrorPage/ErrorPage"
import Step2 from "./components/Step2/Step2"
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import HomePage from "./components/HomePage/HomePage"
import "react-datepicker/dist/react-datepicker.css"
import Step3 from "./components/Step3/Step3"
import Step4 from "./components/Step4/Step4"
import "./index.scss"
import ThemeContext from "./ThemeContext"

const Main = (props) => {
  const theme = useContext(ThemeContext)
  const [startDate, setStartDate] = useState("")
  const value = { startDate, setStartDate }
  return (
    <div className="MainContainer">
      <ThemeContext.Provider value={value}>
        <Header />
        <header className="App-header">Calcule seu Ascendente</header>
        {props.children}
      </ThemeContext.Provider>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Main>
        <HomePage />
      </Main>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/errorpage",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/step2",
    element: (
      <Main>
        <Step2 />
      </Main>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/step3",
    element: (
      <Main>
        <Step3 />
      </Main>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/step4",
    element: (
      <Main>
        <Step4 />
      </Main>
    ),
    errorElement: <ErrorPage />,
  },
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <div className="internal-container">
      <RouterProvider router={router} />
      <Footer />
    </div>
  </React.StrictMode>
)

reportWebVitals()
