import styled from "@emotion/styled"
import InputMask from 'react-input-mask';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
`
export const InputWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  input {
    width: 50vh;
  }
  align-items: center;
`
export const StyledInput = styled.input`
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #0077cc;
    box-shadow: 0 0 5px #0077cc;
  }
`

export const StyledCalendar = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #0077cc;
    box-shadow: 0 0 5px #0077cc;
  }
`

export const MarginTop = styled.div`
  width: 100vw;
  margin-top: 10px;
  margin-bottom: 20px;
`

export const TextWrapper = styled.div`
  margin: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: #131319;
    font-weight: bold;
  }

  & p {
    margin-bottom: 1rem;
  }
`
export const Button = styled.button`
  background-image: radial-gradient(circle at 50% -25%, #ff7171, #ffe966);
  color: #1f1f24;
  font-size: 24px;
  padding: 16px 32px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: rotate(45deg);
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    background-color: #f2d549;
    color: #1f1f24;
    box-shadow: 0 0 10px 5px #f2d549;

    &:before {
      top: 0;
      left: 0;
    }
  }
`

export const Input = styled(InputMask)`
  margin-top: 15px;
  padding: 0.5rem;
  font-size: 2rem;
  color: #fff;
  background-color: #2d283e;
  border: none;
  border-radius: 50px;
  text-align: center;
  text-shadow: 0px 0px 10px #fff;
  box-shadow: 0px 0px 10px #fff;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px #fff, 0px 0px 20px #fff;
  }
  &::placeholder {
    color: #fff;
    opacity: 0.5;
    text-shadow: none;
  }
  &::-webkit-input-placeholder {
    color: #fff;
    opacity: 0.5;
    text-shadow: none;
  }
  &::-moz-placeholder {
    color: #fff;
    opacity: 0.5;
    text-shadow: none;
  }
  &::-ms-input-placeholder {
    color: #fff;
    opacity: 0.5;
    text-shadow: none;
  }
`;
