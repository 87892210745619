import React, { useContext, useState } from "react"
import DatePicker from "react-datepicker"
import * as S from "./styles"
import { useNavigate } from "react-router-dom"
import calculateZodiacSign from "../../utils/calculateZodiacSign"
import ThemeContext from "../../ThemeContext"
import validator from "validator"
import InputMask from 'react-input-mask';

function Step1(props) {
  const context = useContext(ThemeContext)
  const { startDate, setStartDate } = context
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/step2")
    //setClick(true)
  }

    const handleDateChange = (event) => {
      console.log("%c event", "background: yellow; color: red;", event.target.value);
        setStartDate(event.target.value); // update state when input value changes
    };

  return (
    <div>
      <S.TextWrapper>
        Para calcular o seu Ascendente, precisamos conhecer a sua data e hora de
        nascimento para traçar o seu mapa astral e descobrir o seu signo solar.
        Essas informações juntamente com a posição dos planetas e estrelas no
        céu, nos ajudarão a revelar a face que você mostra ao mundo. Informe a
        sua data de nascimento e embarque nessa jornada rumo à sua verdadeira
        essência cósmica.
      </S.TextWrapper>
      <div>
        <label htmlFor="ano-nascimento">Ano de Nascimento:</label>
        {/*<DatePicker*/}
        {/*  dateFormat="dd/MM/yyyy"*/}
        {/*  selected={startDate}*/}
        {/*  onChange={(date) => setStartDate(date)}*/}
        {/*/>*/}
      </div>
        <S.Input
            mask="99/99/9999"
            placeholder="dd/mm/yyyy"
            value={startDate}
            onChange={handleDateChange}
        />
      <S.MarginTop>
        <S.Button onClick={handleClick} type="button">
          Avançar
        </S.Button>
      </S.MarginTop>
    </div>
  )
}

export default Step1
