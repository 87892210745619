import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { getRandomElement } from "../../utils/utils"

import * as S from "./styles"
import { Button, TextWrapper } from "../Step1/styles"

function But(props) {
  return null
}

function Step4(props) {
  const [click, setClick] = useState(false)
  const navigate = useNavigate()
  const handleClick = () => {
    setClick(true)
    if (click) {
      navigate("/")
    }
  }

  const renderAscendent = () => {
    if (click) {
      return (
        <S.Container>
          <TextWrapper>{getRandomElement()}</TextWrapper>
        </S.Container>
      )
    }
    return null
  }
  return (
    <div>
      <S.Container>
        {!click &&
          "Temos todas as informações necessárias para calcular o seu Ascendente."}
      </S.Container>

      <S.Container>{renderAscendent()}</S.Container>
      <S.Container>
        <Button onClick={handleClick} type="button">
          {click ? "Voltar" : "Calcular seu Ascendente"}
        </Button>
      </S.Container>
    </div>
  )
}

export default Step4
