import Header from "../Header/Header"
import Step1 from "../Step1/Step1"
import Footer from "../Footer/Footer"
import { useNavigate } from "react-router-dom"

function HomePage() {
  const navigate = useNavigate()
  return (
    <div>
      <Step1 />
    </div>
  )
}

export default HomePage
