const cancer =
  "Indivíduos com ascendente em Câncer são conhecidos por sua natureza protetora, emocional e carinhosa. Eles são altamente intuitivos e muitas vezes sensíveis às necessidades emocionais dos outros, sendo excelentes em expressar suas emoções e empatizar com os sentimentos dos demais. Os cancerianos são profundamente ligados à família e aos amigos íntimos, e têm uma forte necessidade de criar um ambiente seguro e acolhedor em seus relacionamentos. Eles podem ser reservados e tímidos em situações novas ou desconfortáveis, mas geralmente são leais, afetuosos e confiáveis com aqueles que conhecem bem. Os cancerianos podem ser propensos a mudanças de humor e a sentimentos de insegurança, mas quando se sentem seguros e apoiados, podem ser fortes e resilientes em face de desafios."
const touro =
  "Indivíduos com ascendente em Touro são conhecidos por sua natureza estável, paciente e prática. Eles valorizam a segurança e a estabilidade em todos os aspectos da vida, incluindo finanças, trabalho e relacionamentos. Os taurinos são conhecidos por serem perseverantes e persistentes em busca de seus objetivos, e muitas vezes são bem-sucedidos devido à sua determinação e disciplina. Eles têm uma apreciação pela beleza e pelo conforto, e podem ser particularmente habilidosos em áreas como arte, culinária e jardinagem. Os taurinos podem ser cautelosos e lentos para mudar, mas são altamente leais e confiáveis com aqueles que conhecem bem. Eles podem ser teimosos em relação a suas opiniões e crenças, mas também são conhecidos por serem sensuais, afetuosos e apaixonados em seus relacionamentos íntimos."
const gemeos =
  "Indivíduos com ascendente em Gêmeos são conhecidos por sua natureza curiosa, comunicativa e versátil. Eles adoram explorar novas ideias e experiências, e têm um forte desejo de aprender e se comunicar com os outros. Os geminianos são geralmente muito falantes e sociais, e têm uma facilidade em fazer conexões com pessoas de todos os tipos. Eles podem ser adaptáveis e flexíveis em relação a mudanças e novas situações, mas também podem ser superficiais em seus relacionamentos e interesses. Os geminianos são muitas vezes dotados de uma mente aguçada e habilidades de comunicação, e podem se destacar em áreas como escrita, ensino e comunicação. Eles podem ser emocionalmente imprevisíveis e inconstantes, mas quando se sentem seguros e valorizados, são leais e afetuosos com aqueles que amam."

const geraAscedente = [cancer, touro, gemeos]

export const getRandomElement = (array = geraAscedente) => {
  const index = Math.floor(Math.random() * array.length)
  return array[index]
}
