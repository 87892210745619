import styled from "@emotion/styled"

export const Wrapper = styled.div`
  text-align: center;
  position: relative;
  height: 10vh;
  background-color: #111215;
  color: #0b6bc7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Tomorrow, sans-serif;
  font-size: 0.85rem;

  &:hover {
    color: #1de3c0;
  }

  cursor: pointer;
`
