import React, { useState } from "react"
import * as S from "../Step3/styles"
import { useNavigate } from "react-router-dom"

import TimePicker from "react-time-picker"
import {Button} from "../Step1/styles";

function Step3(props) {
  const [value, onChange] = useState("10:00")
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/step4")
    //setClick(true)
  }
  return (
    <div>
      <S.Title>
        <label htmlFor="hora-nascimento">Hora de Nascimento:</label>
        <div>
          <TimePicker onChange={onChange} value={value} />
        </div>
      </S.Title>

      <Button onClick={handleClick} type="button">
        Avançar
      </Button>
    </div>
  )
}

export default Step3
