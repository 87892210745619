export const calculateZodiacSign = (date) => {
  console.log("%c date", "background: yellow; color: red;", date);
  const [dayD, monthD, yearD] = date.split('/');
  const dateObject = new Date(`${monthD}/${dayD}/${yearD}`);
  const month = dateObject.getMonth() + 1
  const day = dateObject.getDate()

  // Determine o signo do zodíaco com base no mês e dia
  if ((month == 1 && day <= 19) || (month == 12 && day >= 22)) {
    return "Capricórnio"
  } else if ((month == 1 && day >= 20) || (month == 2 && day <= 18)) {
    return "Aquário"
  } else if ((month == 2 && day >= 19) || (month == 3 && day <= 20)) {
    return "Peixes"
  } else if ((month == 3 && day >= 21) || (month == 4 && day <= 19)) {
    return "Áries"
  } else if ((month == 4 && day >= 20) || (month == 5 && day <= 20)) {
    return "Touro"
  } else if ((month == 5 && day >= 21) || (month == 6 && day <= 20)) {
    return "Gêmeos"
  } else if ((month == 6 && day >= 21) || (month == 7 && day <= 22)) {
    return "Câncer"
  } else if ((month == 7 && day >= 23) || (month == 8 && day <= 22)) {
    return "Leão"
  } else if ((month == 8 && day >= 23) || (month == 9 && day <= 22)) {
    return "Virgem"
  } else if ((month == 9 && day >= 23) || (month == 10 && day <= 22)) {
    return "Libra"
  } else if ((month == 10 && day >= 23) || (month == 11 && day <= 21)) {
    return "Escorpião"
  } else {
    return "Sagitário"
  }
}

export default calculateZodiacSign
